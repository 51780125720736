export default {
  name: 'Assinatura',
  components: {
    ComoFuncionaAssinatura: () => import('src/components/ComoFuncionaAssinatura/ComoFuncionaAssinatura.vue'),
    FormAssinatura: () => import('src/components/FormAssinatura/FormAssinatura.vue')
  },
  meta () {
    return {
      title: 'Assinatura - NetCarros',
      description: {
        name: 'description',
        content:
            'Quer saber mais sobre como funciona o programa de assinatura? Acesse agora e tira suas dúvidas!'
      },
      property: {
        name: 'og:image',
        content: 'https://www.netcarros.com.br/seo/netcarros-duvidas.png'
      },
      link: {
        material: {
          rel: 'canonical',
          href: 'https://www.netcarros.com.br' + this.$route.fullPath
        }
      },
      meta: [
        {
          property: 'og:title',
          content: 'Assinatura - NetCarros'
        },
        {
          name: 'description',
          content:
          'Quer saber mais sobre como funciona o programa de assinatura? Acesse agora e tira suas dúvidas!'
        },
        { property: 'revisit-after', content: '5 days' },
        {
          property: 'og:image',
          content: 'https://www.netcarros.com.br/seo/netcarros-duvidas.png'
        },
        {
          property: 'og:image:secure_url',
          content: 'https://www.netcarros.com.br/seo/netcarros-duvidas.png'
        },
        {
          property: 'og:url',
          content: 'https://www.netcarros.com.br' + this.$route.fullPath
        },
        { property: 'og:site_name', content: 'NetCarros' },
        { property: 'fb:admins', content: '', vmid: 'fb:admins' },
        { property: 'og:locale', content: 'pt_BR' }
      ]
    }
  },
  data () {
    return {

    }
  },
  mounted () {
    this.linkpage = 'https://api.whatsapp.com/send?phone=551151980247&text=Olá%20estou%20interessado%20em%20alugar%20um%20carro%20com%20a%20NetCarros.'
    document.querySelector('.botoes a').href = this.linkpage
  },
  methods: {

  },
  watch: {
  },
  created () {
  }
}
